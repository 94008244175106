<template>
  <div class="marketing-league-settings">
    <PageTitle :title="$route.meta.title" hideBtn />

    <section v-if="hasEvent" class="flex justify-between">
      <SectionTitle :title="`${currentEvent.name} ${currentEvent.eventDuration_}`" hideBtn />

      <div class="flex align-self-start">
        <el-button type="danger" @click="deleteHanlder">
          關閉活動
        </el-button>
        <el-button type="primary" @click="editHanlder">
          編輯
        </el-button>
      </div>
    </section>
    <section>
      <div class="event-wrap mb-3">
        <!-- 空狀態 -->
        <div v-show="!hasEvent" class="text-center event-empty">
          <el-empty
            class="p-0"
            :description="text.noData"
            :image="require('@/assets/icon/nodata/no_data.svg')"
          >
            <el-button type="primary" @click="createHandler">新增活動</el-button>
          </el-empty>
        </div>
        <!-- 活動資料 -->
        <div v-show="hasEvent" class="event-data">
          <div v-for="item in eventDataArray" :key="item.name" class="mb-3 flex">
            <span class="event-label">
              {{ item.label }}
            </span>
            <div v-if="item.isImg" class="d-inline-block img-wrapper">
              <img :src="getEventData(item.key || item.name)" alt="">
            </div>
            <span v-else class="event-text">
              {{ getEventData(item.key || item.name) }}
            </span>
          </div>
        </div>
      </div>
      <span v-show="hasEvent" class="event-hint">
        提醒：一次僅能進行一個行銷活動，若要新增活動需先將舊活動關閉
      </span>
    </section>

    <div>
      <el-dialog
        :title="dialog.title"
        :visible.sync="dialog.visible"
        :close-on-click-modal="dialog.mode !== 'create'"
        @close="closeHandler"
        @opened="openHandler"
      >
        <MarketLeagueForm
          v-show="dialog.mode !== 'delete'"
          ref="form"
          :data="editData"
          :mode="dialog.mode"
          @submit="submitHandler"
          @cancel="closeHandler"
        />
        <div v-show="dialog.mode === 'delete'">
          <div class="mb-5">
            <p class="dlg-text">關閉活動後將無法復原，確定要關閉此優惠活動嗎？</p>
          </div>
          <div class="flex justify-content-between">
            <el-button class="dlg-btn" plain @click="closeHandler">取消</el-button>
            <el-button class="dlg-btn" type="danger" @click="cancelHandler">關閉</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MarketLeagueForm from './components/MarketLeagueForm'

import { text } from '@/constants'
import { get as _get, isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

const elLoadingOptions = {
  lock: true,
  target: '.marketing-league-settings',
}

export default {
  name: 'MarketingLeagueSettings',
  components: { MarketLeagueForm },
  data () {
    return {
      text,
      search: {
        name: '',
      },
      dialog: {
        title: '',
        visible: false,
        mode: '',
      },
      editData: {},
      eventDataArray: [
        {
          name: 'img',
          label: '圖片',
          key: 'img.imageURL',
          isImg: true,
        },
        {
          name: 'name',
          label: '活動名稱',
        },
        {
          name: 'eventDuration',
          label: '參加期限',
          key: 'eventDuration_',
        },
        {
          name: 'description',
          label: '活動內容',
        },
        {
          name: 'redeemDuration',
          label: '使用期限',
          key: 'redeemDuration_',
        },
        {
          name: 'discountType',
          label: '優惠形式',
          key: 'discountType_',
        },
        {
          name: 'eventQuota',
          label: '發放數量',
        },
        {
          name: 'shopCategory',
          label: '參加類別',
          key: 'shopCategoryName',
        },
        {
          name: 'shopUrl',
          label: '店家網址',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('marketing-league', {
      shopId: 'shopId',
      currentEvent: 'currentEvent',
    }),
    hasEvent () {
      return !isEmpty(this.currentEvent)
    },
    getEventData () {
      return (key) => _get(this.currentEvent, key, '')
    },
  },
  async mounted () {
    console.log('mounted')
    if (!this.hasEvent) {
      console.log('no event')
      this.getEventHistory()
    }
  },
  methods: {
    openDialog () {

    },
    createHandler () {
      this.dialog.visible = true
      this.dialog.title = '新增活動'
      this.dialog.mode = 'create'

      this.editData = {}
    },
    editHanlder () {
      this.dialog.visible = true
      this.dialog.title = '編輯活動'
      this.dialog.mode = 'edit'

      this.editData = {
        ...this.currentEvent,
      }
    },
    deleteHanlder () {
      this.dialog.visible = true
      this.dialog.title = '關閉活動'
      this.dialog.mode = 'delete'
    },
    async submitHandler (formData) {
      const { mode } = this.dialog
      const actions = {
        create: 'CREATE_PREFERENTIAL_PLAN',
        edit: 'UPDATE_PREFERENTIAL_PLAN',
      }
      const loading = this.$loading({
        target: this.$refs.form.$el,
      })
      const id = this.editData.id
      const res = await this.$store.dispatch(`marketing-league/${actions[mode]}`, { ...formData, id })
      if (!res.OK) {
        this.$message.error(res.error)
      } else {
        this.$refs.form.syncForm({})
        this.closeHandler()
        this.getEventHistory()
      }
      loading.close()
    },
    async cancelHandler () {
      const loading = this.$loading(elLoadingOptions)
      await this.$store.dispatch('marketing-league/CLOSE_PREFERENTIAL_PLAN', {
        id: this.currentEvent.id,
      })
      this.getEventHistory()
      this.closeHandler()
      loading.close()
    },
    closeHandler () {
      this.dialog.visible = false
    },
    openHandler () {
      this.$refs.form.syncForm(this.editData)
    },
    async getEventHistory () {
      const loading = this.$loading(elLoadingOptions)
      await this.$store.dispatch('marketing-league/GET_CURRENT_PREFERENTIAL_PLAN', { status: 'open' })
      loading.close()
    },
  },
}
</script>

<style lang="scss">
.marketing-league-settings {
  .event-wrap {
    background-color: #fff;
    border-radius: 4px;
  }
  .event-empty {
    padding: 80px;
  }
  .event-data {
    padding: 30px 20px;
  }
  .event-label {
    width: 84px;
    font-weight: 500;
    @apply text-gray-100;
    display: inline-block;
    flex-basis: auto;
  }
  .event-text {
    @apply text-gray-80;
    text-overflow: ellipsis;
    flex-shrink: 1;
    display: inline-block;
    max-width: calc(100% - 84px);

  }
  .event-hint {
    @apply text-primary-100;
  }
  .img-wrapper {
    width: 140px;
    height: 140px;
    background-image: url('../../assets/icon/img-placeholder.png');
    border-radius: 4px;
    overflow: hidden;
  }
  .dlg-text {
    @apply text-lg font-medium text-white;
  }
  .dlg-btn {
    width: 140px;
  }
}
</style>
