<template>
  <div class="market-league-form">
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-width="120px"
      label-position="top"
    >
      <el-form-item
        v-for="field in fields"
        :key="field.name"
        :label="field.label"
        :prop="field.prop || field.name"
      >
        <template v-if="field.name === 'img'">
          <UploadButton :img="UploadButtonImg" :isAvatar="true" @change="changePicHandler" />
          <ImageCropper
            v-if="uploadDialog"
            :image="uploadImg"
            :appendBody="true"
            @uploaded="(picUrl) => getImage(field, picUrl)"
            @close="uploadDialog = false"
          />
        </template>
        <component
          :is="field.type"
          v-else
          v-model="field.value"
          :type="field.inputType"
          :min="field.min"
          :max="field.max"
          :start-placeholder="field.startPlaceholder"
          :end-placeholder="field.endPlaceholder"
          :range-separator="field.rangeSeparator"
          :value-format="field.valueFormat"
          :placeholder="field.placehoder || '請填寫'"
          :precision="field.precision"
          :step="field.step"
          :disabled="edit && field.readOnly"
          :pickerOptions="pickerOptions(field.name)"
        >
          <template v-if="field.type === 'el-select'">
            <el-option
              v-for="opt in field.options"
              :key="opt.name"
              :value="opt.value"
              :label="opt.label"
            />
          </template>
        </component>
        <p v-show="field.hint" class="form-hint" style="margin-top: 8px">{{ field.hint }}</p>
      </el-form-item>
      <el-form-item>
        <div class="flex justify-end">
          <el-button plain @click="cancelHandler">取消</el-button>
          <el-button type="primary" @click="submitHandler('form')"> {{ edit ? '儲存': '新增' }}</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton'
import ImageCropper from '@/components/ImageCropper'

import { noEmptyRules } from '@/validation'
import { isEmpty } from 'lodash'
import dayjs from '@/lib/dayjs'

const defaultFormData = {
  description: '',
  discountAmout: 100,
  discountType: 'cash',
  eventDuration: [dayjs().format('YYYY-MM-DD'), ''],
  eventQuota: 1,
  img: null,
  name: '',
  redeemDuration: [dayjs().format('YYYY-MM-DD'), ''],
  shopCategory: '',
  shopUrl: '',
}

export default {
  name: 'MarketLeagueForm',
  components: { UploadButton, ImageCropper },
  props: {
    data: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  data () {
    return {
      fields: [
        {
          name: 'img',
          label: '圖片',
          type: 'UploadButton',
          value: null,
          // value: {
          //   'UserId': '',
          //   'createdAt': '',
          //   'filename': '',
          //   'id': '',
          //   'updatedAt': ''
          // },
          rules: noEmptyRules('請上傳圖片'),
        },
        {
          name: 'name',
          label: '活動名稱',
          type: 'el-input',
          value: '',
          rules: noEmptyRules('請輸入優惠名稱'),
        },
        {
          name: 'description',
          label: '活動內容',
          type: 'el-input',
          value: '',
          rules: noEmptyRules('請輸入優惠內容'),
        },
        {
          name: 'eventDuration',
          label: '參加期限',
          type: 'el-date-picker',
          inputType: 'daterange',
          startPlaceholder: '開始日期',
          endPlaceholder: '结束日期',
          rangeSeparator: '至',
          valueFormat: 'yyyy-MM-dd',
          value: [dayjs().format('YYYY-MM-DD'), ''],
          rules: noEmptyRules('請選擇參加期限'),
          readOnly: true,
        },
        {
          name: 'discountType',
          label: '優惠形式',
          type: 'el-select',
          value: 'cash',
          rules: noEmptyRules('請選擇優惠形式'),
          options: [{ label: '金額', value: 'cash' }, { label: '百分比', value: 'percent' }],
          readOnly: true,
        },
        {
          name: 'discountAmout',
          label: '優惠金額',
          type: 'el-input-number',
          value: 100,
          rules: noEmptyRules('請輸入優惠金額/比例'),
          precision: 0,
          step: 1,
          readOnly: true,
          hint: '範例：打9折請填寫0.90，65折請填寫0.65 (最小為0.01)',
          min: 1,
          max: 999999,
        },
        {
          name: 'redeemDuration',
          label: '使用期限',
          type: 'el-date-picker',
          inputType: 'daterange',
          startPlaceholder: '開始日期',
          endPlaceholder: '结束日期',
          rangeSeparator: '至',
          valueFormat: 'yyyy-MM-dd',
          value: [dayjs().format('YYYY-MM-DD'), ''],
          rules: noEmptyRules('請輸入參加期限'),
        },
        {
          name: 'eventQuota',
          label: '活動名額',
          type: 'el-input-number',
          value: 1,
          rules: noEmptyRules('請輸入活動名額'),
          min: 1,
          max: 9999,
        },
        {
          name: 'shopCategory',
          label: '參加類別',
          type: 'el-select',
          value: '',
          options: [],
          rules: noEmptyRules('請選擇參加類別'),
        },
        {
          name: 'shopUrl',
          label: '店家網址',
          type: 'el-input',
          value: '',
          rules: noEmptyRules('請輸入店家網址'),
        },
      ],
      uploadImg: null, // for ImageCropper prop
      // uploadImg: {
      //   file: 'data:image/jpeg;base64,/',
      //   name: 'filename'
      // },
      uploadDialog: false,
    }
  },
  computed: {
    edit () {
      return this.mode === 'edit'
    },
    formData () {
      return this.fields.reduce((data, field) => {
        data[field.name] = field.value
        return data
      }, {})
    },
    formRules () {
      return this.fields.reduce((data, field) => {
        data[field.name] = field.rules
        return data
      }, {})
    },
    fieldDiscountType () {
      return this.fields.find(field => field.name === 'discountType').value
    },
    // for UploadButton prop
    UploadButtonImg () {
      const [fieldImg] = this.fields
      const res = {
        ...fieldImg.value,
        ...this.uploadImg,
      }
      return isEmpty(fieldImg.value) ? null : res
    },
    disabledDateOptions () {
      const fieldMap = {
        eventDuration: () => dayjs().format('YYYY-MM-DD'),
        redeemDuration: () => this.fields.find(field => field.name === 'eventDuration').value[0],
      }
      return (name) => fieldMap[name]
    },
    pickerOptions () {
      return (name) => {
        const fieldDateOption = this.disabledDateOptions(name)
        if (!fieldDateOption) return
        return {
          disabledDate (time) {
            return time.getTime() < dayjs(fieldDateOption(), 'YYYY-MM-DD').toDate()
          },
        }
      }
    },
  },
  created () {
    this.getEventOptions()
    this.$watch('fieldDiscountType', this.handleDiscountTypeChange)
  },
  methods: {
    async formValidate (formName) {
      const isValid = await this.$refs[formName].validate().catch(res => res)
      return isValid
    },
    formReset (formName) {
      this.$refs[formName].resetFields()
    },
    async submitHandler (formName) {
      if (!await this.formValidate(formName)) {
        return
      }
      this.$emit('submit', this.formData)
    },
    cancelHandler () {
      this.$emit('cancel')
    },
    syncForm (syncData) {
      this.fields.forEach(field => {
        field.value = syncData[field.name] || defaultFormData[field.name]
      })
    },
    changePicHandler (inputPic) {
      this.uploadImg = inputPic
      this.uploadDialog = true
    },
    getImage (field, picURL) {
      field.value = picURL
      this.avatarChanged = true
      this.uploadDialog = false
    },
    async getEventOptions () {
      const res = await this.$store.dispatch('marketing-league/GET_SHOP_AVAILABLE_CATEGORY')
      const [fieldCategory] = this.fields.filter(field => field.name === 'shopCategory')
      fieldCategory.options = res.map(({ name, id }) => ({ label: name, value: id }))
    },
    handleDiscountTypeChange (type) {
      const defaultField = {
        cash: {
          label: '優惠金額',
          precision: 0,
          step: 1,
          value: 100,
          min: 1,
          max: 999999,
        },
        percent: {
          label: '優惠比例',
          precision: 2,
          step: 0.01,
          value: 0.9,
          min: 0.01,
          max: 1,
        },
      }
      const fieldDiscountAmout = this.fields.find(field => field.name === 'discountAmout')
      const newField = defaultField[type]
      fieldDiscountAmout.label = newField.label
      fieldDiscountAmout.precision = newField.precision
      fieldDiscountAmout.step = newField.step
      if (this.mode !== 'edit') {
        fieldDiscountAmout.value = newField.value
      }
      fieldDiscountAmout.min = newField.min
      fieldDiscountAmout.max = newField.max
    },
  },
}
</script>

<style lang="scss">
.market-league-form {
  .form-hint {
    @apply text-gray-80;
    line-height: 1;
    font-size: 14px;
  }
}
</style>
